import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormDataConsumer, REDUX_FORM_NAME } from 'react-admin';
import { arrayPush as arrayPushAction } from 'redux-form';
import { AddCircleOutline as AddIcon } from '@material-ui/icons/index';
import { connect } from 'react-redux';

import {
  getDiscountPercent,
  getDiscountValue,
  getEstimatedTotalPriceExVat,
  getTotalPriceExVat,
} from '../../../../../services/calcOrdersFunc';
import resources from '../../../../../constants/resources';

class AddOrderButton extends Component {
  addRecentItem = () => {
    const {
      arrayPush,
      record: { quantity, weight, productId },
      customerId,
      customers,
      products,
      vatCodes,
    } = this.props;

    const { priceMethod = 0, vatcodeId, metricId, description, ordermethod } = products[productId];
    let { costPricePerUnit, pricePerUnit } = products[productId];
    costPricePerUnit = costPricePerUnit || 0;
    pricePerUnit = pricePerUnit || 0;

    const vatCode = vatCodes[vatcodeId];
    const { discount } = customers[customerId] || {};

    const discPricePerUnit = getDiscountValue(pricePerUnit, discount);
    const disc = getDiscountPercent(pricePerUnit, discPricePerUnit);
    const totalPriceExVat = getTotalPriceExVat(priceMethod, discPricePerUnit, disc, quantity || weight);
    const estimatedTotalPriceExVat = getEstimatedTotalPriceExVat(totalPriceExVat, vatCode);

    arrayPush(REDUX_FORM_NAME, 'saleOrderItems', {
      productId,
      productDescription: description,
      priceMethod,
      costPricePerUnit: costPricePerUnit.toFixed(2),
      pricePerUnit: pricePerUnit.toFixed(2),
      quantity,
      weight,
      discPricePerUnit: discPricePerUnit.toFixed(2),
      disc: disc.toFixed(2),
      totalPriceExVat: totalPriceExVat.toFixed(2),
      totalVatAmount: estimatedTotalPriceExVat.toFixed(2),
      packTypeId: metricId || 0,
      saleType: ordermethod,
    });
  };

  render() {
    return (
      <FormDataConsumer>
        {({ formData }) =>
          formData &&
          !formData.exported && (
            <Button size="small" onClick={this.addRecentItem}>
              <AddIcon />
            </Button>
          )
        }
      </FormDataConsumer>
    );
  }
}

AddOrderButton.propTypes = {
  record: PropTypes.instanceOf(Object),
  arrayPush: PropTypes.func,
  customerId: PropTypes.number.isRequired,
  customers: PropTypes.instanceOf(Object),
  products: PropTypes.instanceOf(Object),
  vatCodes: PropTypes.instanceOf(Object),
};

const mapStateToProps = state => ({
  customers: state.admin.resources[resources.CUSTOMER].data,
  products: state.admin.resources[resources.PRODUCT].data,
  vatCodes: state.admin.resources[resources.VATCODE].data,
});

export default connect(
  mapStateToProps,
  {
    arrayPush: arrayPushAction,
  },
)(AddOrderButton);
