import React, { useEffect } from 'react';
import { bool, instanceOf, string } from 'prop-types';
import { FormDataConsumer, maxLength, ReferenceField, ReferenceInput, required, TextInput } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import * as moment from 'moment';

import AutocompleteInput from '../../../common/ui/AutocompleteInputStyled';
import DOTextField from '../../../common/ui/DOTextField';
import { Content } from '../../../common/ui/DOForm';
import { handleChangeCustomer } from '../services/handleChangeCustomer';
import resources from '../../../../constants/resources';
import formats from '../../../../constants/formats';
import { roles } from '../../../../constants/roles';
import { minimalDate } from '../../../../validators';
import { getCustomerId } from '../../../../provider/tokenUtils';

const minDeliveryDay = moment().add(1, 'days');

const GeneralInfo = props => {
  const {
    isEdit,
    permissions,
    basePath,
    position,
    record: { exported },
    resource,
  } = props;

  const isCustomer = permissions === roles.CUSTOMER_ROLE;

  useEffect(() => {
    const { changeValue, customers, record } = props;
    if (isCustomer) {
      const id = getCustomerId();

      if (!isEdit) {
        // for create we show customer deliveryAddress, but keep current address for edit form
        const { deliveryAddress } = Object.values(customers).find(x => x.id === id) || {};
        changeValue('deliveryAddress', deliveryAddress);

        if (deliveryAddress) {
          changeValue('deliveryAddressId', 0);
        }
      }

      if (!record.deliveryAddressId) {
        changeValue('deliveryAddressId', 0);
      }

      changeValue('customerGroupId', (customers[id] || {}).customerGroupId);
      changeValue('currencyId', (customers[id] || {}).currencyType);
      // changeValue('notes', (customers[id] || {}).notes);
      changeValue('hiddenCustomerId', id);
      changeValue('customerId', id);
    }
  }, [permissions]);

  return (
    <Content position={position} isNewDesign>
      <FormDataConsumer>
        {({ formData }) => (
          <ReferenceInput
            label="Customer Code"
            source="customerId"
            reference={resources.CUSTOMER}
            sort={{ field: 'customerCode', order: 'ASC' }}
            filterToQuery={customerCode => ({ customerCode })}
            validate={!isEdit ? required() : null}
            onChange={handleChangeCustomer(formData, 'code', props)}
            basePath={basePath}
            resource={resource}
          >
            <AutocompleteInput
              optionText={item => (item.customerCode && item.customerCode.trim()) || ''}
              options={{
                fullWidth: true,
                disabled: isEdit || isCustomer,
              }}
            />
          </ReferenceInput>
        )}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData }) => (
          <ReferenceInput
            label="Customer Name"
            source="hiddenCustomerId"
            reference={resources.CUSTOMER}
            sort={{ field: 'companyName', order: 'ASC' }}
            validate={!isEdit ? required() : null}
            onChange={handleChangeCustomer(formData, 'name', props)}
            basePath={basePath}
            resource={resource}
          >
            <AutocompleteInput
              optionText={item => (item.companyName && item.companyName.trim()) || ''}
              options={{
                fullWidth: true,
                disabled: isEdit || isCustomer,
                suggestionsContainerProps: {
                  disablePortal: false,
                },
              }}
            />
          </ReferenceInput>
        )}
      </FormDataConsumer>

      <TextInput source="purchaseOrderNumber" label="PO Number" disabled={!!exported} validate={maxLength(50)} />

      <DateInput
        source="deliveryDate"
        label="Delivery Date"
        options={{
          format: formats.DATE,
          disabled: !!exported,
          autoOk: true,
          minDate: isCustomer ? minDeliveryDay : undefined,
        }}
        validate={isCustomer && !exported ? minimalDate(minDeliveryDay) : null}
      />

      <TextInput source="otherRef" label="Other Ref" disabled={!!exported} validate={maxLength(50)} />

      <FormDataConsumer label="Customer Group">
        {({ formData }) => (
          <ReferenceField
            source="customerGroupId"
            reference={resources.CUSTOMER_GROUP}
            linkType={false}
            allowEmpty
            record={formData}
            key={formData && formData.customerGroupId}
            basePath={basePath}
            resource={resource}
          >
            <DOTextField source="description" />
          </ReferenceField>
        )}
      </FormDataConsumer>
    </Content>
  );
};

export default GeneralInfo;

GeneralInfo.propTypes = {
  isEdit: bool,
  permissions: string,
  basePath: string,
  position: string,
  record: instanceOf(Object),
  resource: string,
};
